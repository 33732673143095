<template>
    <div class="container padding-container">
        <div class="border-container">
            <el-row class="search-container" type="flex" align="middle" justify="space-between">
                <el-col :lg="2" :sm="4" :xs="8">
                    <span class="list-title">订单列表</span>
                </el-col>
                <el-col :lg="22" :sm="20" :xs="16" class="search-area">
                    <el-col :span='3' class="search-item">
                        <el-input class="circular-bead-input" prefix-icon="el-icon-search" v-model="searchForm.sn"
                            placeholder="订单号" @change="pageChange(1)"/>
                    </el-col>
                    <el-col :span='3' class="search-item">
                        <el-select class="circular-bead-select" clearable v-model="searchForm.status"
                            placeholder="订单状态" @change="pageChange(1)">
                            <el-option v-for="item of statusList" :key="item.value" :label="item.name" :value="item.value" />
                        </el-select>
                    </el-col>
                    <el-col :span='8' class="search-item">
                        <div class="date-picker-group">
                            <el-date-picker class="circular-bead-date-picker" v-model="searchForm.beginTime" type="date"
                                value-format="timestamp" @change="pageChange(1)" placeholder="开始时间">
                            </el-date-picker>
                            <div class="split-line">-</div>
                            <el-date-picker class="circular-bead-date-picker" v-model="searchForm.endTime" type="date"
                                value-format="timestamp" @change="pageChange(1)" placeholder="结束时间">
                            </el-date-picker>
                        </div>
                    </el-col>
                </el-col>
            </el-row>

            <div class="table-container">
                <el-table :data="tableData" tooltip-effect="dark" style="width: 100%" size="medium"
                    :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
                    :cell-style="{height: '50px',padding: '5px 0'}" header-align="center">
                    <el-table-column prop="id" label="ID" width="60" align="center" v-if='auth("ADMIN")'/>
                    <el-table-column label="订单号" min-width="180">
                        <div slot-scope="scope" class="hover-text-colourful fz-bold"
                            @click="routerChange(1, scope.row)" style="padding-left: 5px">
                            {{scope.row.sn}}
                        </div>
                    </el-table-column>
                    <el-table-column prop="charge" label="价格" min-width="100">
                        <div slot-scope="scope" class="text-colourful">
                            ¥ {{scope.row.price / 100}}
                        </div>
                    </el-table-column>
                    <el-table-column prop="charge" label="实际支付" min-width="100">
                        <div slot-scope="scope" class="text-colourful">
                            ¥ {{scope.row.charge / 100}}
                        </div>
                    </el-table-column>

                    <el-table-column prop="status" label="状态" min-width="120">
                        <div slot-scope="scope">
                            <i class="status iconfont icon-point" :class="scope.row.status | payStatusColorFilter"/>
                            {{scope.row.status | orderStatusFilter}}
                        </div>
                    </el-table-column>

                    <el-table-column prop="source_type" label="来源渠道" min-width="120">
                        <template slot-scope="scope">
                            {{scope.row.source_type | orderSourceFilter}}
                        </template>
                    </el-table-column>

                    <el-table-column prop="pay_type" label="支付方式" min-width="120">
                        <template slot-scope="scope">
                            <i class="iconfont" :class="scope.row.pay_type | orderTypeIconFilter"></i>
                            {{scope.row.pay_type | orderTypeFilter}}
                        </template>
                    </el-table-column>
                    <el-table-column label="用户" min-width="120" align="center">
                        <template slot-scope="scope">
                            {{scope.row.user ? scope.row.user.name : '-'}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="create_time" label="下单时间" min-width="155" align="center">
                        <template slot-scope="scope">
                            {{scope.row.create_time | timeFilter}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="create_time" label="支付时间" min-width="155" align="center">
                        <template slot-scope="scope">
                            {{scope.row.pay_time | timeFilter}}
                        </template>
                    </el-table-column>
                    <!-- <el-table-column label="操作" width="100" align="center">
                        <template slot-scope="">
                            <el-button type="text" size="small">退款</el-button>
                        </template>
                    </el-table-column> -->
                    <div class="" slot="empty">
                        <no-data></no-data>
                    </div>
                </el-table>
            </div>
            <div class="page-container">
                <el-pagination layout="total, sizes, prev, pager, next" background @size-change="pageSizeChange"
                    @current-change="pageChange" :current-page="currPage" :total="total" :page-size="pageSize">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import Core from '@/core';
export default {
    components: {
        NoData: () => import('@/components/Empty.vue')
    },
    props: {},
    data() {
        return {
            total: 0,
            currPage: 1,
            pageSize: 10,

            searchForm: {
                sn: '',
                phone: '',
                status: '',
                beginTime: '',
                endTime: '',
            },
            statusList: Core.Const.ORDER.STATUS_LIST,

            tableData: []
        };
    },
    watch: {},
    computed: {},
    mounted() {},
    created() {},
    mounted() {
        this.getTableData();
    },
    methods: {
        auth: Core.Utils.auth,
        pageChange(page) {
            this.currPage = page;
            this.getTableData();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getTableData();
        },
        getTableData() {
            Core.Api.CourseOrder.list(
                this.currPage,
                this.pageSize,
                this.searchForm.sn,
                '', '',
                this.searchForm.status,
                '', '',
                Math.round(this.searchForm.beginTime / 1000) || '',
                Math.round(this.searchForm.endTime / 1000) || '',
            ).then(res => {
                console.log('getTableData res:', res)
                this.tableData = res.list;
                this.total = res.count;
            })
        },
        routerChange(key, item) {
            /* switch (key) {
                case 1:
                    this.$router.push('/online-education/detail/institutions?id=' + item.id);
                    break;
            } */
        }
    }
};
</script>

<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
}
</style>